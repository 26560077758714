import { Socket, io } from "socket.io-client";
import create from "zustand";

export interface SocketInterface {
  socket: Socket;
}

export const useSocketStore = create<SocketInterface>((set, get) => ({
  socket: io(`${process.env.REACT_APP_SOCKET_URL}`, {
    transports: ["websocket", "polling"],
    path: "/socket/socket.io",
  }),
  // socket: io("https://api.app-terra.net", {
  //   path: "/socket/socket.io",
  // }),
}));
