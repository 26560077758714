import { Accordion, Button, Card, useAccordionButton } from "react-bootstrap";
import styles from "../assets/tracking.module.css";
import { useParams } from "react-router-dom";
import { useAssignmentStore } from "../../assignments/states/assignment.state";
import { useTrackingMapStore } from "../../maps/states/tracking-maps.state";
import { useTrackingStore } from "../states/tracking.state";
import { Switch } from "@mui/material";
import { useState } from "react";

function CustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionButton(eventKey);

  return (
    <button
      type="button"
      className="btn btn-link"
      style={{
        border: "none",
        outline: "none",
        marginBottom: 0,
        color: "#000",
        fontSize: 12,
      }}
      onClick={decoratedOnClick}
    >
      {children}
    </button>
  );
}

const TrackingDataComponent = () => {
  const { disasterId } = useParams();
  const { getTrackings, trackingLoading } = useTrackingStore();
  const { map } = useTrackingMapStore();
  const { assignments } = useAssignmentStore();

  const [trackingUserId, setTrackingUserId] = useState(undefined);

  const getAllTrackings = () => {
    if (
      disasterId !== undefined &&
      assignments.length > 0 &&
      map !== undefined
    ) {
      const users = assignments.map((item) => item.user);
      getTrackings(map, disasterId, users);
      // getTrackingPhoto({
      //   event_id: disasterId,
      //   page: 0,
      //   size: 1000,
      // });
      // getTrackingVideo({
      //   event_id: disasterId,
      //   page: 0,
      //   size: 1000,
      // });
      setTrackingUserId(undefined);
    }
  };

  const getSingleTracking = (id: string) => {
    if (
      disasterId !== undefined &&
      assignments.length > 0 &&
      map !== undefined
    ) {
      const user = assignments
        .filter((el) => el.user_id === id)
        .map((item) => item.user);
      getTrackings(map, disasterId, user);
    }
  };

  return (
    <Accordion
      defaultActiveKey="0"
      style={{
        width: 300,
        position: "absolute",
        top: 100,
        left: 10,
        zIndex: 100,
        color: "rgb(0, 0, 0)",
      }}
    >
      <Card className={`${styles["card"]}`}>
        <Card.Header
          className={`${styles["card-header"]}`}
          style={{
            height: 30,
            padding: 0,
          }}
        >
          <CustomToggle eventKey="0">
            Penugasan (
            <span>
              {trackingLoading ? (
                <>Loading</>
              ) : (
                <>
                  {
                    assignments.filter(
                      (el) => el.is_reponse === true && el.is_read === true
                    ).length
                  }
                </>
              )}
            </span>
            )
          </CustomToggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body
            className="card-body"
            style={{
              height: 300,
              backgroundColor: "#fff",
              padding: 10,
              fontSize: 11,
            }}
          >
            <div style={{ height: 280, overflowY: "scroll" }}>
              {trackingLoading ? (
                <Button size="sm" disabled>
                  Memuat Data...
                </Button>
              ) : (
                <Button onClick={getAllTrackings} size="sm">
                  Tampilkan Semua Tracking
                </Button>
              )}
              <table
                style={{
                  marginTop: 10,
                  width: 250,
                  border: "1px solid #000",
                }}
              >
                <thead>
                  <tr>
                    <th
                      style={{
                        fontSize: 10,
                        fontWeight: "normal",
                        borderRight: "1px solid #000",
                        borderBottom: "1px solid #000",
                        textAlign: "center",
                      }}
                    >
                      No
                    </th>
                    <th
                      style={{
                        fontSize: 10,
                        fontWeight: "normal",
                        borderRight: "1px solid #000",
                        borderBottom: "1px solid #000",
                        textAlign: "center",
                      }}
                    >
                      Nama
                    </th>
                    <th
                      style={{
                        fontSize: 10,
                        fontWeight: "normal",
                        borderRight: "1px solid #000",
                        borderBottom: "1px solid #000",
                        textAlign: "center",
                      }}
                    >
                      Tracking
                    </th>
                  </tr>
                </thead>
                {trackingLoading ? (
                  <tbody>
                    <tr>
                      <td colSpan={3} style={{ textAlign: "center" }}>
                        <img
                          src={require(`../../../img/loading.gif`)}
                          style={{ width: 30 }}
                          alt="Loading"
                        ></img>
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {assignments
                      ?.filter(
                        (el) => el.is_reponse === true && el.is_read === true
                      )
                      .map((item, index) => {
                        const isActive = trackingUserId === item;
                        return (
                          <tr key={index}>
                            <th
                              style={{
                                fontSize: 10,
                                fontWeight: "normal",
                                borderRight: "1px solid #000",
                                borderBottom: "1px solid #000",
                                textAlign: "center",
                              }}
                            >
                              {index + 1}
                            </th>
                            <th
                              style={{
                                fontSize: 10,
                                fontWeight: "normal",
                                borderRight: "1px solid #000",
                                borderBottom: "1px solid #000",
                                textAlign: "center",
                              }}
                            >
                              {item?.user?.fullName}
                            </th>
                            <th
                              style={{
                                fontSize: 10,
                                fontWeight: "normal",
                                borderRight: "1px solid #000",
                                borderBottom: "1px solid #000",
                                textAlign: "center",
                                cursor: "pointer",
                              }}
                            >
                              <Switch
                                checked={isActive}
                                onChange={() => {
                                  if (isActive) {
                                    getSingleTracking(undefined);
                                    setTrackingUserId(undefined);
                                    getAllTrackings();
                                  } else {
                                    getSingleTracking(item.user_id);
                                    setTrackingUserId(item);
                                  }
                                }}
                                color="primary"
                              />
                            </th>
                          </tr>
                        );
                      })}
                  </tbody>
                )}
              </table>
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default TrackingDataComponent;
