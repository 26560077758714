import styles from "../assets/home.module.css";
import moment from "moment";
import { useEffect, useState } from "react";
import { Table, Button, Modal } from "react-bootstrap";
import { useUserStore } from "../states/user.state";
import { useEventTableStore } from "../states/disaster-event-table.state";
import { useResourceStore } from "../states/resource.state";
import { DisasterData } from "../interfaces/disaster-event.interface";
import { ResourceData } from "../interfaces/resource.interface";
import { UserData } from "../interfaces/user-v2.interface";
import { useCityStore } from "../states/city.state";
import { useProvinceStore } from "../states/province.state";
import { useUserOrganizationStore } from "../states/user-organization.state";
import { useEventTypeStore } from "../states/event-type.state";
import { useResourceTypeGroupStore } from "../states/resource-type-group.state";
import { DisasterEventFilter } from "../providers/disaster-event.provider";
import { UserVolunteerFilter } from "../providers/user.provider";
import { GetResourceParams } from "../providers/resource.provider";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import XLSX from "xlsx";

interface DataBencanaDetailModalProps {
  show: boolean;
  onHide: () => void;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  item: DisasterData;
}

interface DataRelawanDetailModalProps {
  show: boolean;
  onHide: () => void;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  item: UserData;
}

interface DataSumberDayaDetailModalProps {
  show: boolean;
  onHide: () => void;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  item: ResourceData;
}

const DataBencanaDetailModal: React.FC<DataBencanaDetailModalProps> = ({
  show,
  onHide,
  onClick,
  item,
}) => {
  return (
    <Modal show={show} onHide={onHide} dialogClassName="md">
      <Modal.Header className={`${styles["modal-header"]}`}>
        <Modal.Title>
          <h5 className={`${styles["modal-title"]}`}>Lihat Bencana</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{
            fontSize: 14,
            height: 380,
            overflowX: "hidden",
            overflowY: "scroll",
          }}
        >
          <p>
            Tanggal: <br />
            {moment(item?.date_time).format("DD-MM-YYYY")}
          </p>
          <p>
            KIB: <br /> {item?.event_code}
          </p>
          <p>
            Jenis: <br /> {item?.event_type.name}
          </p>
          <p>
            Nama Bencana: <br /> {item?.name}
          </p>
          <p>
            Provinsi: <br /> {item?.province.name}
          </p>
          <p>
            Kabupaten/Kota: <br /> {item?.city.name}
          </p>
          <p>
            Lokasi: <br /> {item?.location}
          </p>
          <p>
            Latitude: <br /> {item?.lat}
          </p>
          <p>
            Longitude: <br /> {item?.lng}
          </p>
          <p>
            Deskripsi: <br /> {item?.description}
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClick}>
          Tutup
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const DataRelawanDetailModal: React.FC<DataRelawanDetailModalProps> = ({
  show,
  onHide,
  onClick,
  item,
}) => {
  return (
    <Modal show={show} onHide={onHide} dialogClassName="md">
      <Modal.Header className={`${styles["modal-header"]}`}>
        <Modal.Title>
          <h5 className={`${styles["modal-title"]}`}>Lihat Relawan</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{
            fontSize: 14,
            height: 380,
            overflowX: "hidden",
            overflowY: "scroll",
          }}
        >
          <p>
            Grup: <br />
            {item?.user_organization_name}
          </p>
          <p>
            Nama: <br />
            {item?.fullName}
          </p>
          <p>
            Jenis Kelamin: <br />
            {item?.gender === "m" ? "Laki-laki" : "Perempuan"}
          </p>
          <p>
            Tanggal Lahir: <br />
            {moment(item?.birthdate).format("DD-MM-YYYY")}
          </p>
          <p>
            Alamat: <br />
            {item?.address}
          </p>
          <p>
            Kota: <br />
            {item?.city_name}
          </p>
          <p>
            Provinsi: <br />
            {item?.province_name}
          </p>
          <p>
            Klasifikasi: <br />
          </p>
          <p>
            Penugasan: <br />
          </p>
          <p>
            Deskripsi: <br />
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClick}>
          Tutup
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const DataSumberDayaDetailModal: React.FC<DataSumberDayaDetailModalProps> = ({
  show,
  onHide,
  onClick,
  item,
}) => {
  return (
    <Modal show={show} onHide={onHide} dialogClassName="md">
      <Modal.Header className={`${styles["modal-header"]}`}>
        <Modal.Title>
          <h5 className={`${styles["modal-title"]}`}>Lihat Institusi</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{
            fontSize: 14,
            height: 380,
            overflowX: "hidden",
            overflowY: "scroll",
          }}
        >
          <p>
            Grup: <br />
            {item?.resource_type_group?.name}
          </p>
          <p>
            Nama: <br /> {item?.name}
          </p>
          <p>
            Lokasi: <br /> {item?.location}
          </p>
          <p>
            Latitude: <br /> {item?.lat}
          </p>
          <p>
            Longitude: <br /> {item?.lng}
          </p>
          <p>
            Kota: <br /> {item?.city}
          </p>
          <p>
            Provinsi: <br /> {item?.province}
          </p>
          <p>
            Kontak: <br /> {item?.contact_name}
          </p>
          <p>
            Telp: <br /> {item?.contact_phone}
          </p>
          <p>
            Deskripsi: <br /> {item?.description}
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClick}>
          Tutup
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const DataByTelkomselDetailModal: React.FC<DataBencanaDetailModalProps> = ({
  show,
  onHide,
  onClick,
  item,
}) => {
  const currencyFormatter = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
  });

  return (
    <Modal show={show} onHide={onHide} dialogClassName="md">
      <Modal.Header className={`${styles["modal-header"]}`}>
        <Modal.Title>
          <h5 className={`${styles["modal-title"]}`}>Lihat Bencana</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{
            fontSize: 14,
            height: 380,
            overflowX: "hidden",
            overflowY: "scroll",
          }}
        >
          <p>
            Tanggal: <br />
            {moment(item?.date_time).format("DD-MM-YYYY")}
          </p>
          <p>
            KIB: <br /> {item?.event_code}
          </p>
          <p>
            Jenis: <br /> {item?.event_type.name}
          </p>
          <p>
            Nama Bencana: <br /> {item?.name}
          </p>
          <p>
            Total Bantuan: <br />{" "}
            {currencyFormatter.format(item?.total_csr_assistance)}
          </p>
          <p>
            Provinsi: <br /> {item?.province.name}
          </p>
          <p>
            Kabupaten/Kota: <br /> {item?.city.name}
          </p>
          <p>
            Lokasi: <br /> {item?.location}
          </p>
          <p>
            Latitude: <br /> {item?.lat}
          </p>
          <p>
            Longitude: <br /> {item?.lng}
          </p>
          <p>
            Deskripsi: <br /> {item?.description}
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClick}>
          Tutup
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const DataBencana = () => {
  const {
    getEventsTable,
    getEventsTableExport,
    eventTableItems,
    eventTableItemsExport,
    eventTableInterface,
    eventTableInterfaceExport,
    loading,
    loadingExport,
    deleteEvent,
  } = useEventTableStore();
  const [showData, setShowData] = useState(false);
  const handleShow = () => setShowData(true);
  const handleClose = () => setShowData(false);
  const [item, setItem] = useState<DisasterData>(undefined);
  const { getProvince, provinceItems } = useProvinceStore();
  const { getCity, cityItems } = useCityStore();
  const { getEventType, eventTypeItems } = useEventTypeStore();
  const times = [
    { value: "2023", label: "2023" },
    { value: "2022", label: "2022" },
    { value: "2021", label: "2021" },
    { value: "2020", label: "2020" },
  ];
  const [eventFilter, setEventFilter] = useState<DisasterEventFilter>({
    is_terra: undefined,
    province_code: undefined,
    city_code: undefined,
    time_filter: undefined,
    event_type_id: undefined,
    name: undefined,
    size: 15,
  });
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(15);
  const handlePageClick = (e: any) => {
    const selectedPage = e.selected;
    setPage(selectedPage);
  };
  const DeleteAlert = (item) => {
    Swal.fire({
      title: "Apakah anda yakin?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Ya",
      denyButtonText: "Tidak",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Data berhasil dihapus!", "", "success");
        deleteEvent(item.id);
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };
  //excel column width
  let disasterDateLength = 7;
  let disasterCodeLength = 3;
  let disasterTypeLength = 5;
  let disasterNameLength = 4;
  let disasterCityLength = 14;
  let disasterProvinceLength = 8;
  eventTableItems.map((item, index) => {
    const disasterDateLengthTemp = moment(item.date_time).format(
      "DD-MM-YYYY"
    ).length;
    if (disasterDateLengthTemp > disasterDateLength) {
      disasterDateLength = disasterDateLengthTemp;
    }
    const disasterCodeLengthTemp = item.event_code.length;
    if (disasterCodeLengthTemp > disasterCodeLength) {
      disasterCodeLength = disasterCodeLengthTemp;
    }
    const disasterTypeLengthTemp = item.event_type.name.length;
    if (disasterTypeLengthTemp > disasterTypeLength) {
      disasterTypeLength = disasterTypeLengthTemp;
    }
    const disasterNameLengthTemp = item.name.length;
    if (disasterNameLengthTemp > disasterNameLength) {
      disasterNameLength = disasterNameLengthTemp;
    }
    const disasterCityLengthTemp = item.city.name.length;
    if (disasterCityLengthTemp > disasterCityLength) {
      disasterCityLength = disasterCityLengthTemp;
    }
    const disasterProvinceLengthTemp = item.province.name.length;
    if (disasterProvinceLengthTemp > disasterProvinceLength) {
      disasterProvinceLength = disasterProvinceLengthTemp;
    }
  });
  const handleOnExport = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    const dateTime = Date.now();
    const data = eventTableItemsExport
      .filter((el) => moment(el?.date_time).format("YYYY") >= "2020")
      .map((item) => ({
        date: moment(item?.date_time).format("DD-MM-YYYY"),
        eventCode: item?.event_code,
        eventType: item?.event_type.name,
        eventName: item?.description,
        city: item?.city?.name,
        province: item?.province?.name,
      }));
    var ws = XLSX.utils.json_to_sheet(data);
    ws["!cols"] = [];
    ws["!cols"][0] = { wch: disasterDateLength };
    ws["!cols"][1] = { wch: disasterCodeLength };
    ws["!cols"][2] = { wch: disasterTypeLength };
    ws["!cols"][3] = { wch: disasterNameLength };
    ws["!cols"][4] = { wch: disasterCityLength };
    ws["!cols"][5] = { wch: disasterProvinceLength };
    var wb = XLSX.utils.book_new();
    XLSX.utils.sheet_add_aoa(
      ws,
      [["Tanggal", "KIB", "Jenis", "Nama", "Kabupaten/Kota", "Provinsi"]],
      { origin: "A1" }
    );
    XLSX.utils.book_append_sheet(wb, ws, "MySheet1");
    XLSX.writeFile(wb, `data_bencana_${dateTime}.xlsx`);
  };

  useEffect(() => {
    getEventsTableExport({ ...eventFilter, size: 100000 });
  }, [eventFilter]);

  useEffect(() => {
    getEventsTable(eventFilter);
  }, []);

  useEffect(() => {
    let filter = eventFilter;
    filter = {
      ...filter,
      page: page,
    };
    setEventFilter(filter);
    getEventsTable(filter);
  }, [page]);

  return (
    <>
      <div className="tab-content">
        <div>
          <div
            className="form-row"
            style={{
              padding: "10px 0",
            }}
          >
            <form className="form-inline" action="">
              <div className={`${styles["w-150px"]}`}>
                <select
                  className={`${styles["w-150px"]} form-control input`}
                  style={{ fontSize: 12 }}
                  onChange={(e) => {
                    setEventFilter({
                      ...eventFilter,
                      event_type_id:
                        e.target.value === "" ? undefined : e.target.value,
                    });
                  }}
                >
                  <option value="">Bencana</option>
                  {eventTypeItems?.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className={`${styles["w-150px"]}`}>
                <select
                  className={`${styles["w-150px"]} form-control input`}
                  style={{ fontSize: 12 }}
                  onChange={(e) => {
                    const provCode = provinceItems.find(
                      (el) => el.id === e.target.value
                    );
                    if (provCode) {
                      getCity({ prov_code: provCode?.prov_code });
                      setEventFilter({
                        ...eventFilter,
                        province_code:
                          e.target.value === "" ? undefined : e.target.value,
                      });
                    } else {
                      setEventFilter({
                        ...eventFilter,
                        province_code: undefined,
                      });
                    }
                  }}
                >
                  <option value="">Provinsi</option>
                  {provinceItems?.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className={`${styles["w-150px"]}`}>
                <select
                  className={`${styles["w-150px"]} form-control input`}
                  style={{ fontSize: 12 }}
                  onChange={(e) => {
                    setEventFilter({
                      ...eventFilter,
                      city_code:
                        e.target.value === "" ? undefined : e.target.value,
                    });
                  }}
                >
                  <option value="">Kabupaten/Kota</option>
                  {cityItems?.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className={`${styles["w-150px"]}`}>
                <select
                  className={`${styles["w-150px"]} form-control input`}
                  style={{ fontSize: 12 }}
                  onChange={(e) => {
                    setEventFilter({
                      ...eventFilter,
                      time_filter:
                        e.target.value === "" ? undefined : e.target.value,
                    });
                  }}
                >
                  <option value="">Pilih Tahun</option>
                  {times.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className={`${styles["w-150px"]}`}>
                <input
                  className={`${styles["w-170px"]} form-control input`}
                  type="text"
                  placeholder="Nama"
                  style={{
                    fontSize: 12,
                    height: 38,
                  }}
                  onChange={(e) => {
                    setEventFilter({
                      ...eventFilter,
                      name: e.target.value === "" ? undefined : e.target.value,
                    });
                  }}
                />
              </div>
              <div style={{ marginLeft: 30 }}>
                <Button
                  variant="info"
                  size="sm"
                  onClick={(e) => {
                    getEventsTable({ ...eventFilter, page: 0 });
                  }}
                >
                  Tampilkan
                </Button>
                {loadingExport ? (
                  <>
                    <Button
                      variant="info"
                      size="sm"
                      style={{ marginLeft: 5 }}
                      disabled
                    >
                      Memuat Data...
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      variant="info"
                      size="sm"
                      style={{ marginLeft: 5 }}
                      onClick={handleOnExport}
                    >
                      Export Excel
                    </Button>
                  </>
                )}
              </div>
            </form>
            <div className="col-12">
              <Table
                className={`${styles["table"]}`}
                style={{
                  marginTop: 10,
                }}
                striped
                bordered
                hover
                size="sm"
                id="tblExportBencana"
              >
                <thead style={{ textAlign: "center" }}>
                  <tr>
                    <th>Tanggal</th>
                    <th>KIB</th>
                    <th>Jenis</th>
                    <th>Nama</th>
                    <th>Kabupaten/Kota</th>
                    <th>Provinsi</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {loading && (
                    <tr>
                      <td colSpan={7} style={{ textAlign: "center" }}>
                        <img src={require(`../../../img/loading.gif`)}></img>
                      </td>
                    </tr>
                  )}
                  {eventTableItems.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{moment(item?.date_time).format("DD-MM-YYYY")}</td>
                        <td>{item?.event_code}</td>
                        <td>{item?.event_type.name}</td>
                        <td>{item?.name}</td>
                        <td>{item?.city.name}</td>
                        <td>{item?.province.name}</td>
                        <td>
                          <a
                            href=""
                            onClick={(e) => {
                              e.preventDefault();
                              setItem(item);
                              handleShow();
                            }}
                          >
                            Lihat
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <div className="row">
                <div
                  className="col-4"
                  style={{
                    textAlign: "left",
                    fontSize: 12,
                  }}
                >
                  Halaman {page + 1} dari{" "}
                  {eventTableInterface?.data?.total_pages}, menampilkan{" "}
                  {eventTableInterface?.data?.items.length} dari{" "}
                  {eventTableInterface?.data?.total}
                </div>
                <div className="col-8">
                  <ReactPaginate
                    previousLabel={"«"}
                    nextLabel={"»"}
                    breakLabel={"..."}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    pageCount={eventTableInterface?.data?.total_pages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={10}
                    onPageChange={handlePageClick}
                    containerClassName={
                      "pagination pagination-sm m-0 float-right"
                    }
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DataBencanaDetailModal
        show={showData}
        onHide={handleClose}
        onClick={handleClose}
        item={item}
      />
    </>
  );
};

const DataRelawan = () => {
  const {
    userInterface,
    userInterfaceExport,
    getUsers,
    getUsersExport,
    userItems,
    userItemsExport,
    loading,
    loadingExport,
    deleteUser,
  } = useUserStore();
  const [showRelawan, setShowRelawan] = useState(false);
  const [show, setShow] = useState(false);
  const handleCloseExport = () => setShow(false);
  const handleShow = () => setShowRelawan(true);
  const handleClose = () => setShowRelawan(false);
  const [item, setItem] = useState<UserData>(undefined);
  const { getProvince, provinceItems } = useProvinceStore();
  const { getCity, cityItems } = useCityStore();
  const { getUserOrganization, userOrganizationItems } =
    useUserOrganizationStore();
  const [userFilter, setUserFilter] = useState<UserVolunteerFilter>({
    user_organization_id: undefined,
    province_id: undefined,
    city_id: undefined,
    name: undefined,
    size: 15,
  });
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(15);
  const handlePageClick = (e: any) => {
    const selectedPage = e.selected;
    setPage(selectedPage);
  };
  const DeleteAlert = (item) => {
    Swal.fire({
      title: "Apakah anda yakin?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Ya",
      denyButtonText: "Tidak",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Data berhasil dihapus!", "", "success");
        deleteUser(item.id);
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };
  //excel column width
  let volunteerGroupLength = 4;
  let volunteerNameLength = 4;
  let volunteerGenderLength = 13;
  let volunteerPhoneLength = 4;
  let volunteerDutyLength = 9;
  let volunteerCityLength = 14;
  let volunteerProvinceLength = 8;
  userItems.map((item, index) => {
    const volunteerGroupLengthTemp = item.user_organization_name.length;
    if (volunteerGroupLengthTemp > volunteerGroupLength) {
      volunteerGroupLength = volunteerGroupLengthTemp;
    }
    const volunteerNameLengthTemp = item.fullName.length;
    if (volunteerNameLengthTemp > volunteerNameLength) {
      volunteerNameLength = volunteerNameLengthTemp;
    }
    const volunteerGenderLengthTemp = item.gender.length;
    if (volunteerGenderLengthTemp > volunteerGenderLength) {
      volunteerGenderLength = volunteerGenderLengthTemp;
    }
    const volunteerPhoneLengthTemp = item.phone_number.length;
    if (volunteerPhoneLengthTemp > volunteerPhoneLength) {
      volunteerPhoneLength = volunteerPhoneLengthTemp;
    }
    const volunteerCityLengthTemp = item.city_name.length;
    if (volunteerCityLengthTemp > volunteerCityLength) {
      volunteerCityLength = volunteerCityLengthTemp;
    }
    const volunteerProvinceLengthTemp = item.province_name.length;
    if (volunteerProvinceLengthTemp > volunteerProvinceLength) {
      volunteerProvinceLength = volunteerProvinceLengthTemp;
    }
  });
  const handleOnExport = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    const dateTime = Date.now();
    const data = userItemsExport.map((item) => ({
      group: item?.user_organization_name,
      name: item?.fullName,
      gender:
        item?.gender === "m"
          ? (item?.gender).replace("m", "Laki-laki")
          : (item?.gender).replace("f", "Perempuan"),
      phone: item?.phone_number,
      isDuty: item?.is_duty ? "Ya" : "Tidak",
      city: item?.city_name,
      province: item?.province_name,
    }));
    var ws = XLSX.utils.json_to_sheet(data);
    ws["!cols"] = [];
    ws["!cols"][0] = { wch: volunteerGroupLength };
    ws["!cols"][1] = { wch: volunteerNameLength };
    ws["!cols"][2] = { wch: volunteerGenderLength };
    ws["!cols"][3] = { wch: volunteerPhoneLength };
    ws["!cols"][4] = { wch: volunteerDutyLength };
    ws["!cols"][5] = { wch: volunteerCityLength };
    ws["!cols"][6] = { wch: volunteerProvinceLength };
    var wb = XLSX.utils.book_new();
    XLSX.utils.sheet_add_aoa(
      ws,
      [
        [
          "Grup",
          "Nama",
          "Jenis Kelamin",
          "Telp",
          "Penugasan",
          "Kabupaten/Kota",
          "Provinsi",
        ],
      ],
      { origin: "A1" }
    );
    XLSX.utils.book_append_sheet(wb, ws, "MySheet1");
    XLSX.writeFile(wb, `data_relawan_${dateTime}.xlsx`);
  };

  useEffect(() => {
    getUsersExport({ ...userFilter, size: 100000 });
  }, [userFilter]);

  useEffect(() => {
    getUsers(userFilter);
  }, []);

  useEffect(() => {
    getUserOrganization();
  }, []);

  useEffect(() => {
    let filter = userFilter;
    filter = {
      ...filter,
      page: page,
    };
    setUserFilter(filter);
    getUsers(filter);
  }, [page]);

  useEffect(() => {}, [item]);

  return (
    <>
      <div className="tab-content">
        <div>
          <div
            className="form-row"
            style={{
              padding: "10px 0",
            }}
          >
            <form className="form-inline" action="">
              <div className={`${styles["w-150px"]}`}>
                <select
                  className={`${styles["w-150px"]} form-control input`}
                  style={{ fontSize: 12 }}
                  onChange={(e) => {
                    setUserFilter({
                      ...userFilter,
                      user_organization_id:
                        e.target.value === "" ? undefined : e.target.value,
                    });
                  }}
                >
                  <option value="" selected>
                    Grup
                  </option>
                  {userOrganizationItems?.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className={`${styles["w-150px"]}`}>
                <select
                  className={`${styles["w-150px"]} form-control input`}
                  style={{ fontSize: 12 }}
                  onChange={(e) => {
                    const provCode = provinceItems.find(
                      (el) => el.id === e.target.value
                    );
                    if (provCode) {
                      getCity({ prov_code: provCode?.prov_code });
                      setUserFilter({
                        ...userFilter,
                        province_id:
                          e.target.value === "" ? undefined : e.target.value,
                      });
                    } else {
                      setUserFilter({
                        ...userFilter,
                        province_id: undefined,
                      });
                    }
                  }}
                >
                  <option value="">Provinsi</option>
                  {provinceItems?.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className={`${styles["w-150px"]}`}>
                <select
                  className={`${styles["w-150px"]} form-control input`}
                  style={{ fontSize: 12 }}
                  onChange={(e) => {
                    setUserFilter({
                      ...userFilter,
                      city_id:
                        e.target.value === "" ? undefined : e.target.value,
                    });
                  }}
                >
                  <option value="">Kabupaten/Kota</option>
                  {cityItems?.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className={`${styles["w-150px"]}`}>
                <input
                  className={`${styles["w-170px"]} form-control input`}
                  type="text"
                  placeholder="Nama"
                  style={{
                    fontSize: 12,
                    height: 38,
                  }}
                  onChange={(e) => {
                    setUserFilter({
                      ...userFilter,
                      name: e.target.value === "" ? undefined : e.target.value,
                    });
                  }}
                />
              </div>
              <div style={{ marginLeft: 30 }}>
                <Button
                  variant="info"
                  size="sm"
                  onClick={(e) => {
                    getUsers({ ...userFilter, page: 0 });
                  }}
                >
                  Tampilkan
                </Button>
                {loadingExport ? (
                  <>
                    <Button
                      variant="info"
                      size="sm"
                      style={{ marginLeft: 5 }}
                      disabled
                    >
                      Memuat Data...
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      variant="info"
                      size="sm"
                      style={{ marginLeft: 5 }}
                      onClick={handleOnExport}
                    >
                      Export Excel
                    </Button>
                  </>
                )}
              </div>
            </form>
            <div className="col-12">
              <Table
                className={`${styles["table"]}`}
                style={{
                  marginTop: 10,
                }}
                striped
                bordered
                hover
                size="sm"
                id="tblExportRelawan"
              >
                <thead style={{ textAlign: "center" }}>
                  <tr>
                    <th>Grup</th>
                    <th>Nama</th>
                    <th>Jenis Kelamin</th>
                    <th>Telp</th>
                    <th>Penugasan</th>
                    <th>Kabupaten/Kota</th>
                    <th>Provinsi</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {loading && (
                    <tr>
                      <td colSpan={8} style={{ textAlign: "center" }}>
                        <img src={require(`../../../img/loading.gif`)}></img>
                      </td>
                    </tr>
                  )}
                  {userItems?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item?.user_organization_name}</td>
                        <td>{item?.fullName}</td>
                        <td>
                          {item?.gender === "m"
                            ? (item?.gender).replace("m", "Laki-laki")
                            : (item?.gender).replace("f", "Perempuan")}
                        </td>
                        <td>{item?.phone_number}</td>
                        <td>{item?.is_duty ? "Ya" : "Tidak"}</td>
                        <td>{item?.city_name}</td>
                        <td>{item?.province_name}</td>
                        <td>
                          <a
                            href=""
                            onClick={(e) => {
                              e.preventDefault();
                              setItem(item);
                              handleShow();
                            }}
                          >
                            Lihat
                          </a>
                          |<a href="">Ulang PIN</a>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <div className="row">
                <div
                  className="col-4"
                  style={{
                    textAlign: "left",
                    fontSize: 12,
                  }}
                >
                  Halaman {page + 1} dari {userInterface?.data?.total_pages},
                  menampilkan {userInterface?.data?.items.length} dari{" "}
                  {userInterface?.data?.total}
                </div>
                <div className="col-8">
                  <ReactPaginate
                    previousLabel={"«"}
                    nextLabel={"»"}
                    breakLabel={"..."}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    pageCount={userInterface?.data?.total_pages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={10}
                    onPageChange={handlePageClick}
                    containerClassName={
                      "pagination pagination-sm m-0 float-right"
                    }
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DataRelawanDetailModal
        show={showRelawan}
        onHide={handleClose}
        onClick={handleClose}
        item={item}
      />
    </>
  );
};

const DataSumberDaya = () => {
  const {
    getResource,
    getResourceExport,
    resourceData,
    resourceDataExport,
    resourceItemsExport,
    resourceItems,
    resourceLoading,
    resourceLoadingExport,
    deleteResource,
  } = useResourceStore();
  const [showResource, setShowResource] = useState(false);
  const handleShow = () => setShowResource(true);
  const handleClose = () => setShowResource(false);
  const [item, setItem] = useState<ResourceData>(undefined);
  const { getProvince, provinceItems } = useProvinceStore();
  const { getCity, cityItems } = useCityStore();
  const { getResourceTypeGroup, resourceTypeGroupItems } =
    useResourceTypeGroupStore();
  const [resourceFilter, setResourceFilter] = useState<GetResourceParams>({
    resource_type_group_id: undefined,
    province_code: undefined,
    city_code: undefined,
    name: undefined,
    size: 15,
  });
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(15);
  const handlePageClick = (e: any) => {
    const selectedPage = e.selected;
    setPage(selectedPage);
  };
  const DeleteAlert = (item) => {
    Swal.fire({
      title: "Apakah anda yakin?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Ya",
      denyButtonText: "Tidak",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Data berhasil dihapus!", "", "success");
        deleteResource(item.id);
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };
  //excel column width
  let resourceGroupLength = 4;
  let resourceNameLength = 4;
  let resourceContactLength = 6;
  let resourcePhoneLength = 4;
  let resourceCityLength = 14;
  let resourceProvinceLength = 8;
  resourceItems.map((item, index) => {
    const resourceGroupLengthTemp = item.resource_type_group.name.length;
    if (resourceGroupLengthTemp > resourceGroupLength) {
      resourceGroupLength = resourceGroupLengthTemp;
    }
    const resourceNameLengthTemp = item.name.length;
    if (resourceNameLengthTemp > resourceNameLength) {
      resourceNameLength = resourceNameLengthTemp;
    }
    const resourceContactLengthTemp = item.contact_name.length;
    if (resourceContactLengthTemp > resourceContactLength) {
      resourceContactLength = resourceContactLengthTemp;
    }
    const resourcePhoneLengthTemp = item.contact_phone.length;
    if (resourcePhoneLengthTemp > resourcePhoneLength) {
      resourcePhoneLength = resourcePhoneLengthTemp;
    }
    const resourceCityLengthTemp = item.city.length;
    if (resourceCityLengthTemp > resourceCityLength) {
      resourceCityLength = resourceCityLengthTemp;
    }
    const resourceProvinceLengthTemp = item.province.length;
    if (resourceProvinceLengthTemp > resourceProvinceLength) {
      resourceProvinceLength = resourceProvinceLengthTemp;
    }
  });
  const handleOnExport = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    const dateTime = Date.now();
    const data = resourceItemsExport.map((item) => ({
      group: item?.resource_type_group?.name,
      name: item?.name,
      contactName: item?.contact_name,
      phone: item?.contact_phone,
      city: item?.city,
      province: item?.province,
    }));
    var ws = XLSX.utils.json_to_sheet(data);
    ws["!cols"] = [];
    ws["!cols"][0] = { wch: resourceGroupLength };
    ws["!cols"][1] = { wch: resourceNameLength };
    ws["!cols"][2] = { wch: resourceContactLength };
    ws["!cols"][3] = { wch: resourcePhoneLength };
    ws["!cols"][4] = { wch: resourceCityLength };
    ws["!cols"][5] = { wch: resourceProvinceLength };
    ws["!cols"][6] = { hidden: true };
    var wb = XLSX.utils.book_new();
    XLSX.utils.sheet_add_aoa(
      ws,
      [["Grup", "Nama", "Kontak", "Telp", "Kabupaten/Kota", "Provinsi"]],
      { origin: "A1" }
    );
    XLSX.utils.book_append_sheet(wb, ws, "MySheet1");
    XLSX.writeFile(wb, `data_sumberdaya_${dateTime}.xlsx`);
  };

  useEffect(() => {
    getResourceExport({ ...resourceFilter, size: 100000 });
  }, [resourceFilter]);

  useEffect(() => {
    getResource(resourceFilter);
  }, []);

  useEffect(() => {
    let filter = resourceFilter;
    filter = {
      ...filter,
      page: page,
    };
    setResourceFilter(filter);
    getResource(filter);
  }, [page]);

  useEffect(() => {
    getResourceTypeGroup();
  }, []);

  return (
    <>
      <div className="tab-content">
        <div>
          <div
            className="form-row"
            style={{
              padding: "10px 0",
            }}
          >
            <form className="form-inline" action="">
              <div className={`${styles["w-150px"]}`}>
                <select
                  className={`${styles["w-150px"]} form-control input`}
                  style={{ fontSize: 12 }}
                  onChange={(e) => {
                    setResourceFilter({
                      ...resourceFilter,
                      resource_type_group_id:
                        e.target.value === "" ? undefined : e.target.value,
                    });
                  }}
                >
                  <option value="">Grup</option>
                  {resourceTypeGroupItems?.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className={`${styles["w-150px"]}`}>
                <select
                  className={`${styles["w-150px"]} form-control input`}
                  style={{ fontSize: 12 }}
                  onChange={(e) => {
                    const provCode = provinceItems.find(
                      (el) => el.id === e.target.value
                    );
                    if (provCode) {
                      getCity({ prov_code: provCode?.prov_code });
                      setResourceFilter({
                        ...resourceFilter,
                        province_code:
                          e.target.value === "" ? undefined : e.target.value,
                      });
                    } else {
                      setResourceFilter({
                        ...resourceFilter,
                        province_code: undefined,
                      });
                    }
                  }}
                >
                  <option value="">Provinsi</option>
                  {provinceItems?.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className={`${styles["w-150px"]}`}>
                <select
                  className={`${styles["w-150px"]} form-control input`}
                  style={{ fontSize: 12 }}
                  onChange={(e) => {
                    setResourceFilter({
                      ...resourceFilter,
                      city_code:
                        e.target.value === "" ? undefined : e.target.value,
                    });
                  }}
                >
                  <option value="">Kabupaten/Kota</option>
                  {cityItems?.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className={`${styles["w-150px"]}`}>
                <input
                  className={`${styles["w-170px"]} form-control input`}
                  type="text"
                  placeholder="Nama"
                  style={{
                    fontSize: 12,
                    height: 38,
                  }}
                  onChange={(e) => {
                    setResourceFilter({
                      ...resourceFilter,
                      name: e.target.value === "" ? undefined : e.target.value,
                    });
                  }}
                />
              </div>
              <div style={{ marginLeft: 30 }}>
                <Button
                  variant="info"
                  size="sm"
                  onClick={(e) => {
                    getResource({ ...resourceFilter, page: 0 });
                  }}
                >
                  Tampilkan
                </Button>
                {resourceLoadingExport ? (
                  <>
                    <Button
                      variant="info"
                      size="sm"
                      style={{ marginLeft: 5 }}
                      disabled
                    >
                      Memuat Data...
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      variant="info"
                      size="sm"
                      style={{ marginLeft: 5 }}
                      onClick={handleOnExport}
                    >
                      Export Excel
                    </Button>
                  </>
                )}
              </div>
            </form>
            <div className="col-12">
              <Table
                className={`${styles["table"]}`}
                style={{
                  marginTop: 10,
                }}
                striped
                bordered
                hover
                size="sm"
                id="tblExportSumberDaya"
              >
                <thead style={{ textAlign: "center" }}>
                  <tr>
                    <th>Grup</th>
                    <th>Nama</th>
                    <th>Kontak</th>
                    <th>Telp</th>
                    <th>Kota</th>
                    <th>Provinsi</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {resourceLoading && (
                    <tr>
                      <td colSpan={7} style={{ textAlign: "center" }}>
                        <img src={require(`../../../img/loading.gif`)}></img>
                      </td>
                    </tr>
                  )}
                  {resourceItems?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item?.resource_type_group?.name}</td>
                        <td>{item?.name}</td>
                        <td>{item?.contact_name}</td>
                        <td>{item?.contact_phone}</td>
                        <td>{item?.city}</td>
                        <td>{item?.province}</td>
                        <td>
                          <a
                            href=""
                            onClick={(e) => {
                              e.preventDefault();
                              setItem(item);
                              handleShow();
                            }}
                          >
                            Lihat
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <div className="row">
                <div
                  className="col-4"
                  style={{
                    textAlign: "left",
                    fontSize: 12,
                  }}
                >
                  Halaman {page + 1} dari {resourceData?.data?.total_pages},
                  menampilkan {resourceData?.data?.items.length} dari{" "}
                  {resourceData?.data?.total}
                </div>
                <div className="col-8">
                  <ReactPaginate
                    previousLabel={"«"}
                    nextLabel={"»"}
                    breakLabel={"..."}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    pageCount={resourceData?.data?.total_pages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={10}
                    onPageChange={handlePageClick}
                    containerClassName={
                      "pagination pagination-sm m-0 float-right"
                    }
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DataSumberDayaDetailModal
        show={showResource}
        onHide={handleClose}
        onClick={handleClose}
        item={item}
      />
    </>
  );
};

const DataByTelkomsel = () => {
  const {
    getEventsTableTerra,
    getEventsTableExportTerra,
    getEventsGrandTotalTerra,
    eventGrandTotalTerra,
    eventTableItemsTerra,
    eventTableItemsExportTerra,
    eventTableInterfaceTerra,
    eventTableInterfaceExportTerra,
    loadingTerra,
    loadingExportTerra,
    deleteEvent,
  } = useEventTableStore();
  const [showData, setShowData] = useState(false);
  const handleShow = () => setShowData(true);
  const handleClose = () => setShowData(false);
  const [item, setItem] = useState<DisasterData>(undefined);
  const { getProvince, provinceItems } = useProvinceStore();
  const { getCity, cityItems } = useCityStore();
  const { getEventType, eventTypeItems } = useEventTypeStore();
  const times = [
    { value: "2023", label: "2023" },
    { value: "2022", label: "2022" },
    { value: "2021", label: "2021" },
    { value: "2020", label: "2020" },
  ];
  const [eventFilterTerra, setEventFilterTerra] = useState<DisasterEventFilter>(
    {
      is_terra: true,
      province_code: undefined,
      city_code: undefined,
      time_filter: undefined,
      event_type_id: undefined,
      name: undefined,
      size: 15,
    }
  );
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(15);
  const handlePageClick = (e: any) => {
    const selectedPage = e.selected;
    setPage(selectedPage);
  };
  const currencyFormatter = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
  });
  const DeleteAlert = (item) => {
    Swal.fire({
      title: "Apakah anda yakin?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Ya",
      denyButtonText: "Tidak",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Data berhasil dihapus!", "", "success");
        deleteEvent(item.id);
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };
  //excel column width
  let disasterDateLength = 7;
  let disasterCodeLength = 3;
  let disasterTypeLength = 5;
  let disasterNameLength = 4;
  let disasterAssistanceLength = 16;
  let disasterCityLength = 14;
  let disasterProvinceLength = 8;
  eventTableItemsTerra.map((item, index) => {
    const disasterDateLengthTemp = moment(item.date_time).format(
      "DD-MM-YYYY"
    ).length;
    if (disasterDateLengthTemp > disasterDateLength) {
      disasterDateLength = disasterDateLengthTemp;
    }
    const disasterCodeLengthTemp = item.event_code.length;
    if (disasterCodeLengthTemp > disasterCodeLength) {
      disasterCodeLength = disasterCodeLengthTemp;
    }
    const disasterTypeLengthTemp = item.event_type.name.length;
    if (disasterTypeLengthTemp > disasterTypeLength) {
      disasterTypeLength = disasterTypeLengthTemp;
    }
    const disasterNameLengthTemp = item.name.length;
    if (disasterNameLengthTemp > disasterNameLength) {
      disasterNameLength = disasterNameLengthTemp;
    }
    const disasterCityLengthTemp = item.city.name.length;
    if (disasterCityLengthTemp > disasterCityLength) {
      disasterCityLength = disasterCityLengthTemp;
    }
    const disasterProvinceLengthTemp = item.province.name.length;
    if (disasterProvinceLengthTemp > disasterProvinceLength) {
      disasterProvinceLength = disasterProvinceLengthTemp;
    }
  });
  const handleOnExport = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    const dateTime = Date.now();
    const data = eventTableItemsExportTerra
      .filter((el) => moment(el?.date_time).format("YYYY") >= "2020")
      .map((item) => ({
        date: moment(item?.date_time).format("DD-MM-YYYY"),
        eventCode: item?.event_code,
        eventType: item?.event_type.name,
        eventName: item?.description,
        csrAssistance: item?.total_csr_assistance,
        city: item?.city?.name,
        province: item?.province?.name,
      }));
    var ws = XLSX.utils.json_to_sheet(data);
    ws["!cols"] = [];
    ws["!cols"][0] = { wch: disasterDateLength };
    ws["!cols"][1] = { wch: disasterCodeLength };
    ws["!cols"][2] = { wch: disasterTypeLength };
    ws["!cols"][3] = { wch: disasterNameLength };
    ws["!cols"][4] = { wch: disasterAssistanceLength };
    ws["!cols"][5] = { wch: disasterCityLength };
    ws["!cols"][6] = { wch: disasterProvinceLength };
    var wb = XLSX.utils.book_new();
    XLSX.utils.sheet_add_aoa(
      ws,
      [
        [
          "Tanggal",
          "KIB",
          "Jenis",
          "Nama",
          "Total Bantuan",
          "Kabupaten/Kota",
          "Provinsi",
        ],
      ],
      { origin: "A1" }
    );
    XLSX.utils.book_append_sheet(wb, ws, "MySheet1");
    XLSX.writeFile(wb, `data_bencana_${dateTime}.xlsx`);
  };

  useEffect(() => {
    getEventsTableExportTerra({ ...eventFilterTerra, page: 0, size: 100000 });
  }, [eventFilterTerra]);

  useEffect(() => {
    getEventsTableTerra(eventFilterTerra);
    getEventsGrandTotalTerra({ ...eventFilterTerra, page: 0, size: 100000 });
  }, []);

  useEffect(() => {
    let filter = eventFilterTerra;
    filter = {
      ...filter,
      page: page,
    };
    setEventFilterTerra(filter);
    getEventsTableTerra(filter);
  }, [page]);

  const totalQuantities = eventGrandTotalTerra?.reduce((total, item) => {
    return total + item.total_csr_assistance;
  }, 0);

  return (
    <>
      <div className="tab-content">
        <div>
          <div
            className="form-row"
            style={{
              padding: "10px 0",
            }}
          >
            <form className="form-inline" action="">
              <div className={`${styles["w-150px"]}`}>
                <select
                  className={`${styles["w-150px"]} form-control input`}
                  style={{ fontSize: 12 }}
                  onChange={(e) => {
                    setEventFilterTerra({
                      ...eventFilterTerra,
                      event_type_id:
                        e.target.value === "" ? undefined : e.target.value,
                    });
                  }}
                >
                  <option value="">Bencana</option>
                  {eventTypeItems?.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className={`${styles["w-150px"]}`}>
                <select
                  className={`${styles["w-150px"]} form-control input`}
                  style={{ fontSize: 12 }}
                  onChange={(e) => {
                    const provCode = provinceItems.find(
                      (el) => el.id === e.target.value
                    );
                    if (provCode) {
                      getCity({ prov_code: provCode?.prov_code });
                      setEventFilterTerra({
                        ...eventFilterTerra,
                        province_code:
                          e.target.value === "" ? undefined : e.target.value,
                      });
                    } else {
                      setEventFilterTerra({
                        ...eventFilterTerra,
                        province_code: undefined,
                      });
                    }
                  }}
                >
                  <option value="">Provinsi</option>
                  {provinceItems?.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className={`${styles["w-150px"]}`}>
                <select
                  className={`${styles["w-150px"]} form-control input`}
                  style={{ fontSize: 12 }}
                  onChange={(e) => {
                    setEventFilterTerra({
                      ...eventFilterTerra,
                      city_code:
                        e.target.value === "" ? undefined : e.target.value,
                    });
                  }}
                >
                  <option value="">Kabupaten/Kota</option>
                  {cityItems?.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className={`${styles["w-150px"]}`}>
                <select
                  className={`${styles["w-150px"]} form-control input`}
                  style={{ fontSize: 12 }}
                  onChange={(e) => {
                    setEventFilterTerra({
                      ...eventFilterTerra,
                      time_filter:
                        e.target.value === "" ? undefined : e.target.value,
                    });
                  }}
                >
                  <option value="">Pilih Tahun</option>
                  {times.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className={`${styles["w-150px"]}`}>
                <input
                  className={`${styles["w-170px"]} form-control input`}
                  type="text"
                  placeholder="Nama"
                  style={{
                    fontSize: 12,
                    height: 38,
                  }}
                  onChange={(e) => {
                    setEventFilterTerra({
                      ...eventFilterTerra,
                      name: e.target.value === "" ? undefined : e.target.value,
                    });
                  }}
                />
              </div>
              <div style={{ marginLeft: 30 }}>
                <Button
                  variant="info"
                  size="sm"
                  onClick={(e) => {
                    getEventsTableTerra({ ...eventFilterTerra, page: 0 });
                    getEventsGrandTotalTerra({
                      ...eventFilterTerra,
                      page: 0,
                      size: 100000,
                    });
                  }}
                >
                  Tampilkan
                </Button>
                {loadingExportTerra ? (
                  <>
                    <Button
                      variant="info"
                      size="sm"
                      style={{ marginLeft: 5 }}
                      disabled
                    >
                      Memuat Data...
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      variant="info"
                      size="sm"
                      style={{ marginLeft: 5 }}
                      onClick={handleOnExport}
                    >
                      Export Excel
                    </Button>
                  </>
                )}
              </div>
            </form>
            <div className="col-12">
              <Table
                className={`${styles["table"]}`}
                style={{
                  marginTop: 10,
                }}
                striped
                bordered
                hover
                size="sm"
                id="tblExportBencana"
              >
                <thead style={{ textAlign: "center" }}>
                  <tr>
                    <th>Tanggal</th>
                    <th>KIB</th>
                    <th>Jenis</th>
                    <th>Nama</th>
                    <th>Total Bantuan</th>
                    <th>Kabupaten/Kota</th>
                    <th>Provinsi</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {loadingTerra && (
                    <tr>
                      <td colSpan={7} style={{ textAlign: "center" }}>
                        <img src={require(`../../../img/loading.gif`)}></img>
                      </td>
                    </tr>
                  )}
                  {eventTableItemsTerra.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{moment(item?.date_time).format("DD-MM-YYYY")}</td>
                        <td>{item?.event_code}</td>
                        <td>{item?.event_type.name}</td>
                        <td>{item?.name}</td>
                        <td>
                          {currencyFormatter.format(item?.total_csr_assistance)}
                        </td>
                        <td>{item?.city.name}</td>
                        <td>{item?.province.name}</td>
                        <td>
                          <a
                            href=""
                            onClick={(e) => {
                              e.preventDefault();
                              setItem(item);
                              handleShow();
                            }}
                          >
                            Lihat
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                  {/* New row to display total quantities */}
                  <tr>
                    <td
                      colSpan={4}
                      style={{ textAlign: "left", fontWeight: "bold" }}
                    >
                      Grand Total Bantuan:
                    </td>
                    <td style={{ fontWeight: "bold" }}>
                      {currencyFormatter.format(totalQuantities)}
                    </td>
                    <td colSpan={3}></td>
                  </tr>
                </tbody>
              </Table>
              <div className="row">
                <div
                  className="col-4"
                  style={{
                    textAlign: "left",
                    fontSize: 12,
                  }}
                >
                  Halaman {page + 1} dari{" "}
                  {eventTableInterfaceTerra?.data?.total_pages}, menampilkan{" "}
                  {eventTableInterfaceTerra?.data?.items.length} dari{" "}
                  {eventTableInterfaceTerra?.data?.total}
                </div>
                <div className="col-8">
                  <ReactPaginate
                    previousLabel={"«"}
                    nextLabel={"»"}
                    breakLabel={"..."}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    pageCount={eventTableInterfaceTerra?.data?.total_pages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={10}
                    onPageChange={handlePageClick}
                    containerClassName={
                      "pagination pagination-sm m-0 float-right"
                    }
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DataByTelkomselDetailModal
        show={showData}
        onHide={handleClose}
        onClick={handleClose}
        item={item}
      />
    </>
  );
};

export {
  DataBencana,
  DataRelawan,
  DataSumberDaya,
  DataByTelkomsel,
  DataBencanaDetailModal,
  DataRelawanDetailModal,
  DataSumberDayaDetailModal,
};
